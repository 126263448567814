<template>
  <div>
    <shop-head currentPageName="AGREEMENT"></shop-head>
    <div class="container">
      <div class="text title">BBmart</div>
      <el-descriptions class="information" border :column="2">
        <el-descriptions-item label="MOB">
          <a href="tel:+8613450473308">+8613450473308</a>
        </el-descriptions-item>
        <el-descriptions-item label="E-mail">
          <a href="mailto:ann.wu@apware.cn">ann.wu@apware.cn</a>
        </el-descriptions-item>
        <el-descriptions-item label="Add">Rm 2201 North Tower, New World Times Centre，2191 Guangyuan East Rd, Tianhe District, Guangzhou, Guangdong Province, China, 510507</el-descriptions-item>
      </el-descriptions>
      <div class="img-box">
        <img src="../../assets/images/map.png" alt="">
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import shopHead from '../../components/shop/head'
import commonFooter from '../../components/common/footer'
export default {
  components: {
    shopHead,
    commonFooter
  },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss" scoped>
.img-box {
  width: 100%;
  display: block;
  margin: 20px auto;
  img {
    width: 100%;
  }
}
.text {
  width: 100%;
  line-height: 26px;
  font-size: 16px;
  text-align: justify;
  &.bottom {
    margin-bottom: 20px;
  }
  &.title {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0 10px;
  }
}
.information {
  a {
    color: unset;
  }
}
</style>